import type { InitAppForPageFn } from '@wix/yoshi-flow-editor';

import type { IAppData } from './types';

import { resolveVerticalsBaseUrls } from 'controller/verticals';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServices,
  flowApi,
): Promise<IAppData> => {
  const verticalsBaseUrls = await resolveVerticalsBaseUrls(wixCodeApi.site);

  return { verticalsBaseUrls };
};
